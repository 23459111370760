import React, {useState, useEffect, useCallback} from "react";
import Appbar from "../components/Appbar";
import Drawer from "../components/Drawer";
import Footer from "../components/Footer";
import TableDataCom from "../components/TableDataCom";
import {crud} from "../services/CrudFactory";
import moment from "moment";
import FaqsDialog from "../dialog/FaqsDialog";
import {CREATE_FAQ, UPDATE_FAQ, DELETE_FAQ} from "../constant";
import {$user} from "../services/UserFactory";

const Faqs = () => {
    const [state, setState] = useState({
        faqs: [],
        faq: null,
        onSave: null
    });

    const getFaqData = async ({page, limit, type = ""}) => {
        const {data: {faqs, total}} = await crud.get("retrieve/web/faqs", {
            page,
            limit,
            type
        });

        setState(prev => ({
            ...prev,
            faqs: faqs,
            total: total
        }))
    }

    // useEffect(() => {
    //     getFaqData();
    // }, []);


    const tableHeaders = [
        "Question For",
        "Question",
        "Answer",
        "Create At",
    ];
    return (
        <>
            <Appbar/>
            <Drawer/>
            <TableDataCom type title={"Faq's"} headers={tableHeaders} data={state.faqs} getData={getFaqData}
                          getRow={(faq) => {
                              const tableCells = [
                                  faq.type,
                                  faq.ques,
                                  faq.answer,
                                  moment(faq.createdAt).format(crud.dateFormat),
                              ];
                              return {
                                  id: faq.id,
                                  cells: tableCells,
                              }
                          }}
                          deleteRow={async ({id}) => {
                              $user.hasPermission(DELETE_FAQ) &&
                              await crud.confirm();
                              await crud.post("delete/web/faq", {id})
                          }}
                          editRow={(faq) => new Promise(resolve => {
                              $user.hasPermission(UPDATE_FAQ) &&
                              setState(prev => ({
                                  ...prev,
                                  faq,
                                  onSave: resolve
                              }))
                          })}
                          createRow={(faq) => new Promise(resolve => {
                              $user.hasPermission(CREATE_FAQ) &&
                              setState(prevState => ({
                                  ...prevState,
                                  faq: {},
                                  onSave: resolve
                              }))
                          })}
                          total={state.total}
            />
            <FaqsDialog open={!!state.faq}
                        close={() => setState(prev => ({...prev, faq: null}))}
                        faq={state.faq}
                        onSave={state.onSave}
            />
            <Footer/>
        </>
    )
}

export default Faqs;
