import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import {
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  //   DialogTitle,
  //   DialogContent,
  //   DialogActions,
  //   Grid,
  //   Divider,
  //   TextField,
  //   MenuItem,
  Slide,
  TextField,
  //   DialogContentText,
  //   TableCell,
  //   TableRow,
  //   TableHead,
  //   TableBody,
  //   Paper,
  //   TableContainer,
  //   IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { crud } from "../services/CrudFactory";
// import { Table } from "react-bootstrap";
// import { makeStyles } from "@material-ui/core/styles";

// import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Pagination } from "pagination-ui";


export default function PaymentReceived(props) {

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });
  const classes = useStyles();

  const [paymentData, setPaymentData] = useState([]);
  const [total, setTotal] = useState(0);

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  const changeStatus = async (e,val) => {
    const {
      data
    } = await crud.update(`update/web/payment`,{id:val});
    if (data.type === "success"){

    }
  };

  useEffect(async () => {
    if (props.id !== ""){
      const data = await crud.get(`retrieve/web/check-qr-payment`,{qrId:props.id.qrId});
      setPaymentData(data?.data?.result);
    }
  }, [props.id]);

  console.log(paymentData)

  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  ];
  return (
    // <div>
    <Dialog
      fullWidth
      TransitionComponent={Slide}
      // aria-labelledby="customized-dialog-title"
      open={props.open}
      maxWidth={"lg"}
      // TransitionProps={{
      //   onEnter: () => {},
      // }}
      onClose={props.onClose}
    >
      <Grid
        container
        xs={12}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <DialogTitle>Payments Details</DialogTitle>
        </Grid>

        <Grid item>
          <DialogTitle>
            <IconButton onClick={props.onClose}>
              <Close />
            </IconButton>
          </DialogTitle>
        </Grid>
      </Grid>
      <Divider />

      <DialogContent>
        {(paymentData?.count > 0)? (<Grid container>
          <Grid item xs={6}>
            <h6>professionalId</h6>
            <h5>{props.id.professionalId}</h5>
          </Grid>
          <Grid item xs={6}>
            <h6>Name</h6>
            <h5>{props.id.name}</h5>
          </Grid>
          <Grid item xs={6}>
            <h6>Due Amount</h6>
            <h5>{props.id.totalAmount}</h5>
          </Grid>
          <Grid item xs={6}>
            <h6>Pay Id</h6>
            <h5>{paymentData?.item[0]?.id}</h5>
          </Grid>
          <Grid item xs={6}>
            <h6>Pay Amount</h6>
            <h5>{paymentData?.item[0]?.amount}</h5>
          </Grid>
          <Grid item xs={6}>
            <h6>Status</h6>
            <h5>{paymentData?.item[0]?.status}</h5>
          </Grid>
          <Grid item xs={6}>
            <h6>Method</h6>
            <h5>{paymentData?.item[0]?.method}</h5>
          </Grid>
        </Grid>) : (<h2>No record</h2>)}
      </DialogContent>

      {/* <Paper> */}
      {/* <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead stickyHeader size="small">
              <TableRow>
                <TableCell style={{ background: "#e466a3", color: "#fff" }}>
                  Id
                </TableCell>
                <TableCell
                  style={{ background: "#e466a3", color: "#fff" }}
                  align="right"
                >
                  Amount
                </TableCell>
                <TableCell
                  style={{ background: "#e466a3", color: "#fff" }}
                  align="right"
                >
                  Category
                </TableCell>
                <TableCell
                  style={{ background: "#e466a3", color: "#fff" }}
                  align="right"
                >
                  Service
                </TableCell>
                <TableCell
                  style={{ background: "#e466a3", color: "#fff" }}
                  align="right"
                >
                  Qty
                </TableCell>
                <TableCell
                  style={{ background: "#e466a3", color: "#fff" }}
                  align="right"
                >
                  Payment Type
                </TableCell>
                <TableCell
                  style={{ background: "#e466a3", color: "#fff" }}
                  align="right"
                >
                  Payment Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row?.id}
                //   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row?.amount}
                  </TableCell>
                  <TableCell align="right">{row?.category}</TableCell>
                  <TableCell align="right">{row?.service}</TableCell>
                  <TableCell align="right">{row?.qty}</TableCell>
                  <TableCell align="right">{row?.paymentType}</TableCell>
                  <TableCell align="right">{row?.paymentStatus}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
      {/* </Paper> */}
    </Dialog>
    // </div>
  );
}
