import {
  AppBar,
  Box, FormControl, InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles, MenuItem, Select,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import { Dashboard, Menu } from "@material-ui/icons";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import Drawer from "./drawer-mobile/Drawer";
import { $user } from "../services/UserFactory";
import {
  VIEW_NOTIFICATIONS,
  VIEW_KYC,
  VIEW_JOB,
  VIEW_DASHBOARD,
  VIEW_SUPPORT_TICKET,
} from "../constant";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  apnone: {
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
  },
  dropdown:{
    paddingLeft:"10px",
    paddingRight:"10px",
    overflow:"hidden",
    height:"40px",
  }
}));

function Appbar() {
  const classes = useStyles();
  return (
    <Fragment>
      <AppBar color="inherit" position="sticky">
        <Toolbar>
          {/*<div className="ml-4">*/}
          {/*  <Link to="/dashboard">*/}
          {/*    <img*/}
          {/*      src={logo}*/}
          {/*      width="50"*/}
          {/*      height="50"*/}
          {/*      //   className="side-logo"*/}
          {/*      style={{*/}
          {/*        marginLeft: "0px",*/}
          {/*        padding: "0px",*/}
          {/*      }}*/}
          {/*      alt="logo"*/}
          {/*    />*/}
          {/*  </Link>*/}
          {/*</div>*/}
          <div className={classes.grow} />
          <div style={{ display: "flex" }} className={classes.apnone}>
            {$user.hasPermission(VIEW_DASHBOARD) && (
              <ListItem
                button
                to="/dashboard"
                component={Link}
                className={classes.title}
              >
                <Dashboard color="primary" className="mr-2" />
                <ListItemText primary="Dashboard" className="text-black" />
              </ListItem>
            )}

            {$user.hasPermission(VIEW_JOB) && (
              <ListItem button to="/jobs" component={Link}>
                <WorkOutlineIcon color="primary" className="mr-2" />
                <ListItemText primary="Jobs" color="primary" />
              </ListItem>
            )}

            {$user.hasPermission(VIEW_KYC) && (
              <ListItem button to="/professional-kyc" component={Link}>
                <i className="far fa-id-card mr-2" id="drawer_icon_color" />
                <ListItemText primary="Professional&nbsp;Kyc" color="primary" />
              </ListItem>
            )}
            {$user.hasPermission(VIEW_SUPPORT_TICKET) && (
              <ListItem button to="/support-ticket" component={Link}>
                <HeadsetMicIcon color="primary" className="mr-2" />
                <ListItemText primary="Support&nbsp;Ticket" color="primary" />
              </ListItem>
            )}
            {$user.hasPermission(VIEW_NOTIFICATIONS) && (
              <ListItem button to="/notification" component={Link}>
                <AddAlertIcon color="primary" className="mr-2" />
                <ListItemText primary="Notification" color="primary" />
              </ListItem>
            )}

            <Tooltip title="Log Out">
              <ListItem
                className="d-flex justify-content-center align-items-center ml-2 mr-3 mt-1 p-2"
                component={Link}
                to="/logout"
              >
                <PowerSettingsNewIcon
                  color="primary"
                  to="/logout"
                  style={{ fontSize: "25px", marginRight: "10px" }}
                />
                <ListItemText
                  primary={
                    JSON.parse(localStorage.getItem("user-info")).user.name
                  }
                  color="primary"
                />
              </ListItem>
            </Tooltip>

          </div>
          {/*<Menu className={classes.menunone} />*/}
          <Drawer />
        </Toolbar>
      </AppBar>
    </Fragment>
  );
}

export default Appbar;
