import {
    Box,
    makeStyles,
    Typography,
    Grid,
    Container,
    Paper,
} from "@material-ui/core";
import React, {Fragment, useEffect, useState} from "react";
import Appbar from "../components/Appbar";
import Drawer from "../components/Drawer";
import Footer from "../components/Footer";
import {crud} from "../services/CrudFactory";
import {PERMANENET_HIDE} from "../constant";
import {$user} from "../services/UserFactory";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: "50px",
        marginBottom: "80px",
        padding: "18px"
    },
}));

export default function Dashboard() {
    const classes = useStyles();
    const [dashboardData, setDashboard] = useState({});

    const getDashboardData = async () => {
        const {data} = await crud.get("retrieve/web/dashboard");
        // console.log(data,"===========data");
        setDashboard(data);
    }

    useEffect(() => {
        getDashboardData();
    }, [])
    const data = [
        {
            title: "Total Active Jobs",
            count: dashboardData?.total_active_orders || 0
        },
        {
            title: "Total Pending Jobs",
            count: dashboardData?.total_pending_orders || 0
        },
        {
            title: "Total Completed Jobs",
            count: dashboardData?.total_delivered_orders || 0
        },
        {
            title:"Total Customer Cancelled the Service",
            count:dashboardData?.total_customer_cancelled_orders || 0
        },
        {
            title:"Total Customers",
            count:dashboardData?.total_customer || 0
        },
        {
            title:"Total Professionals",
            count: dashboardData?.total_professionals || 0
        },
        {
            title: "Total Panel Users",
            count: dashboardData?.total_panel_users || 0
        },
        {
            title: "Total Categories",
            count: dashboardData?.total_categories || 0
        },
        {
            title: "Total Services",
            count:dashboardData?.total_services || 0
        }
    ];
    return (
        <Fragment>
            <Appbar/>
            <Drawer/>
            <Container maxWidth={"xl"}>
                <Paper className={classes.root}>
                    <Grid container spacing={2}>

                        {
                            data?.map((item,index)=><Grid item xs={12} sm={3} md={3} key={index}>
                                <Box
                                    border={1.6}
                                    borderRadius={10}
                                    p={0.5}
                                    borderColor="primary.main"
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    <Typography style={{fontSize:"15px",}} className="font-weight-bold" >
                                        {item?.title}
                                          </Typography>
                                    <Typography variant="h6" className="font-weight-bold">
                                        {item?.count}
                                    </Typography>
                                </Box>
                            </Grid>)
                        }
                    </Grid>
                </Paper>
            </Container>
            <Footer/>
        </Fragment>
    );
}
