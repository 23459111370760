import React, { Fragment, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Typography,
  TextField,
  Grid,
  MenuItem,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
  Avatar,
  Chip,
} from "@material-ui/core";
import { List } from "@material-ui/icons";
import Appbar from "../components/Appbar";
import Drawer from "../components/Drawer";
import Footer from "../components/Footer";
import { crud } from "../services/CrudFactory";
import moment from "moment";
import AddButton from "../components/AddButton";
import { Pagination } from "pagination-ui";
import SkeletonUse from "../components/SkeletonUse";
import { Tr, Td } from "../components";
import { $user } from "../services/UserFactory";
import {
  CREATE_SERVICE,
  UPDATE_SERVICE,
  CHANGE_SERVICE_STATUS,
  DELETE_SERVICE,
  CHANGE_PROFESSIONAL_STATUS,
} from "../constant";
import TableDataCom from "../components/TableDataCom";
import DefaultUserImage from "../images/userDefaultImage.jpg";
import _ from "lodash";
import PaymentDetailsDialog from "../dialog/PaymentDetailsDialog";
import ImagePreview from "../components/ImagePreview";

export default function Payments() {
  const [state, setState] = useState({
    onSave: null,
  });
  const [professionals, setProfessionals] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [total, setTotal] = useState(0);
    const [professionalImage, setProfessionalImage] = useState(null);
    const [paymentId, setPaymentId] = useState({

    });

  const getProfessionalUsers = async ({ page, limit, filters, search }) => {
    const {
      data: { professionals, total },
    } = await crud.get("retrieve/web/professional-users", {
      page,
      limit,
      search,
      ...filters,
    });
    console.log( { professionals, total },"======================");

    setProfessionals(professionals);
    setTotal(total);
  };

    // const revenuePercentage = professionals.map((ele,index) => index==0 ?ele.revenuePercentage: 0);
    // console.log(revenuePercentage,"==============revenuePercentage");



  //   useEffect(() => {
  //   getProfessionalUsers();
  // }, []);

  const tableHeaders = [
    "Profile Photo",
    "Customer Name",
    "Mobile",
    "Email",
    // "Category",
    "Create At",
    "Status",

  ];
  return (
    <React.Fragment>
      <Appbar />
      <Drawer />

      <TableDataCom
        from_date
        to_date
        search
        state={professionals}
        setState={setProfessionals}
        title={"Professional User"}
        headers={tableHeaders}
        data={professionals}
        getData={getProfessionalUsers}
        getRow={(professional) => {
          const tableCells = [
            <Avatar
              style={{
                border: "1px solid grey",
                width: "37px",
                height: "37px",
                cursor: "pointer",
              }}
              src={
                !_.isEmpty(professional.profilePicture)
                  ? crud.baseUrl2 +
                    "professional-profiles/" +
                    professional.profilePicture
                  : DefaultUserImage
              }
              onClick={() => setProfessionalImage(professional)}
            />,
            professional.name,
            professional.mobile,
            professional.email || "NA",
            // <div className="d-flex">
            //   {professional?.professional_services?.map((item) => (
            //     <div>
            //       <Chip
            //         size="small"
            //         className="ml-1"
            //         label={item.categories?.name}
            //       />
            //     </div>
            //   ))}
            // </div>,
              moment(professional.createdAt).format(crud.dateFormat),
            <span
              style={{
                background: `${professional.isActive ? "#5fdba7" : "#F96161"}`,
                padding: "5px 10px",
                borderRadius: "10px",
                fontSize: "12px",
                color: "#FFFFFF",
              }}
            >
              {professional.isActive ? "Active" : "Inactive"}
            </span>,
          ];
          return {
            id: professional.id,
            cells: tableCells,
            actions: (getData) => (
              <>
                {$user.hasPermission(CHANGE_PROFESSIONAL_STATUS) && (
                  <FormControlLabel
                    className="mt-2 ml-1"
                    control={
                      <Switch
                        color="primary"
                        size="small"
                        checked={professional.isActive}
                        onChange={async (e) => {
                          const { checked } = e.target;
                          await crud.post("status/web/professional-user", {
                            id: professional.id,
                          });
                          getData({ clear: false });
                        }}
                      />
                    }
                  />
                )}
                {$user.hasPermission(CHANGE_PROFESSIONAL_STATUS) && (
                  <IconButton onClick={() => (
                  setPaymentId({id:professional.id,revenuePercentage:professional.revenuePercentage}),
                      setDialogVisible(true)
                  )}>
                    <List />
                  </IconButton>
                )}
              </>
            ),
          };
        }}
        switchRow={(professional) =>
          new Promise((resolve) => {
            setState((prev) => ({
              ...prev,
              onSave: resolve,
            }));
            setProfessionals(professional);
          })
        }
        total={total}
      />
        { dialogVisible && <PaymentDetailsDialog
            open={dialogVisible}
            id={paymentId}
            onClose={() => setDialogVisible(false)}
            // revenuePercentage={paymentId.revenuePercentage}
        />}
      <ImagePreview
        open={!!professionalImage}
        close={() => setProfessionalImage(null)}
        imageUrl={professionalImage?.profilePicture}
        title={"Professional Image"}
        imageSize={"100%"}
        path={"/professional-profiles/"}
        defaultImage={DefaultUserImage}
      />
      <Footer />
    </React.Fragment>
  );
}
