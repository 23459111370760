import React, { useState, useEffect } from "react";
import Appbar from "../components/Appbar";
import Drawer from "../components/Drawer";
import Footer from "../components/Footer";
import TableDataCom from "../components/TableDataCom";
import { crud } from "../services/CrudFactory";
import moment from "moment";
import { Switch, FormControlLabel, Avatar, Chip, makeStyles } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import _ from "lodash";
import ImagePreview from "../components/ImagePreview";
import DefaultUserImage from "../images/userDefaultImage.jpg"
import { $user } from "../services/UserFactory";
import { CHANGE_PROFESSIONAL_STATUS } from "../constant";
import { Button } from '@material-ui/core';
import RevenueDialog from "../dialog/RevenueDialog";


const useStyles = makeStyles({
    root: {
        display: "flex",
        alignItems: "center",
    },
    editButton: {
        marginRight: "35px",
    },
    btn: {
        height: "25px",
    }
});

const ProfessionalUser = () => {
    const classes = useStyles();
    const [open, setOpen] = useState({
        isOpen: false,
        data: {}
    });

    const [state, setState] = useState({
        onSave: null
    });
    const [professionals, setProfessionals] = useState([]);
    const [total, setTotal] = useState(0);
    const [professionalImage, setProfessionalImage] = useState(null);
    const [revPercentValue, setRevPercentValue] = useState('')


    const handleRevenuePercent = (e) => {
        setRevPercentValue(e.target.value)
    }

    const handleClickOpen = (professional) => {
        setOpen({
            isOpen: true,
            data: professional?.revenuePercentage,
            id: professional?.id
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const addRevenuePercent = async () => {
        // console.log(open,"=======open ",professional)
        try {
            const data = await crud.post("update/web/revenuePercentage", {
                revenuePercentage: revPercentValue,
                id: open?.id,
            })
            getProfessionalUsers()

        } finally {
            setOpen(false)
        }
    }
    // const getProfessionalUsers = async ({ page, limit, filters, search }) => {
    //     const { data: { professionals, total } } = await crud.get("retrieve/web/professional-users", {
    //         page,
    //         limit,
    //         search,
    //         ...filters
    //     });
    //     setProfessionals(professionals);
    //     setTotal(total);
    // }

    const getProfessionalUsers = async () => {
        const { data: { professionals, total } } = await crud.get("retrieve/web/professional-users",
          );
    // console.log(...professionals,"===============professionals");
        setProfessionals(professionals);
        setTotal(total);
    }


    useEffect(() => {
        getProfessionalUsers();
    }, []);


    const tableHeaders = [
        "Profile Photo",
        "Customer Name",
        "Mobile",
        "Email",
        "Category",
        "Create At",
        "Status",
        "Revenue Percentage",

    ];
    return (
        <>
            <Appbar />
            <Drawer />

            <TableDataCom from_date to_date search state={professionals}
                setState={setProfessionals} title={"Professional User"} headers={tableHeaders}
                data={professionals}
                getData={getProfessionalUsers}
                getRow={(professional) => {
                    const tableCells = [
                        <Avatar

                            style={{
                                border: "1px solid grey",
                                width: "37px",
                                height: "37px",
                                cursor: "pointer"
                            }}
                            src={!_.isEmpty(professional.profilePicture) ? crud.baseUrl2 + "professional-profiles/" + professional.profilePicture : DefaultUserImage}
                            onClick={() => setProfessionalImage(professional)}
                        />,
                        professional.name,
                        professional.mobile,
                        professional.email || "NA",
                        <div className="d-flex">
                            {professional?.professional_services?.map(item => <div>
                                <Chip size="small" className="ml-1"
                                    label={item.categories?.name} /></div>)
                            }</div>,

                        moment(professional.createdAt).format(crud.dateFormat),
                        <span style={{
                            background: `${professional.isActive ? "#5fdba7" : "#F96161"}`,
                            padding: "5px 10px",
                            borderRadius: "10px",
                            fontSize: "12px",
                            color: "#FFFFFF"
                        }}>
                            {professional.isActive ? "Active" : "Inactive"}</span>,
                        <span className={classes.root}>
                            <span className={classes.editButton}>
                                {professional.revenuePercentage && (professional.revenuePercentage + "%") || "0%"}
                            </span>

                            <Button onClick={() => handleClickOpen({revenuePercentage:professional?.revenuePercentage,id:professional?.id})} variant="contained"
                                color="secondary" className={classes.btn}> Edit </Button>


                        </span>

                    ];
                    return {
                        id: professional.id,
                        cells: tableCells,
                        actions: getData => <>
                            {
                                $user.hasPermission(CHANGE_PROFESSIONAL_STATUS) && <FormControlLabel
                                    className="mt-2 ml-1"
                                    control={
                                        <Switch color="primary" size="small" checked={professional.isActive}
                                            onChange={async e => {
                                                const { checked } = e.target;
                                                await crud.post("status/web/professional-user", {
                                                    id: professional.id,
                                                });
                                                getData({ clear: false });
                                            }}
                                        />
                                    }
                                />
                            }
                        </>
                    }
                }}
                switchRow={(professional) => new Promise(resolve => {
                    setState(prev => ({
                        ...prev,
                        onSave: resolve
                    }));
                    setProfessionals(professional);
                })}
                total={total}

            />
            <ImagePreview open={!!professionalImage} close={() => setProfessionalImage(null)}
                imageUrl={professionalImage?.profilePicture} title={"Professional Image"}
                imageSize={"100%"} path={"/professional-profiles/"}
                defaultImage={DefaultUserImage} />
            <RevenueDialog
                handleClose={handleClose}
                handleRevenuePercent={handleRevenuePercent}
                addRevenuePercent={addRevenuePercent}
                handleClickOpen={handleClickOpen}
                revPercentValue={revPercentValue}
                setRevPercentValue={setRevPercentValue}
                open={open}
                professional = {professionals}
                setOpen={setOpen}
            />
            <Footer />
        </>
    )
}

export default ProfessionalUser;

