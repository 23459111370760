import React, {Fragment, useCallback, useEffect, useState} from "react";
import {crud} from "../services/CrudFactory";
import Dialog from "@material-ui/core/Dialog";
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    MenuItem,
    Select,
    TextField,
    makeStyles,
    debounce
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import {KeyboardDateTimePicker} from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import DownloadCSV from "../utils/DownloadCSV";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
    field: {
        margin: theme.spacing(1),
        width: '30ch',
    },
}));


const CsvGenerateModal = ({isVisible, onClose}) => {
    const classes = useStyles();

    // const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
    const [data, Setdata] = React.useState({
        name: "",
        mobile: "",
        from: "",
        to: ""
    });
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [csvData, setCsvData] = React.useState([]);

    const loading = open && options.length === 0;


    const handleChange = (key, value) => {
        Setdata({
            ...data,
            [key]: value
        });
    };

    function sleep(delay = 0) {
        return new Promise((resolve) => {
            setTimeout(resolve, delay);
        });
    }

    const getProfessional = async (e) => {
        console.log(e.target.value)
        const {data: {professionals}} = await crud.get("retrieve/web/professional-users", {search: e.target.value});
        console.log(professionals)
        setOptions(professionals)
        await sleep(1e3);

    }

    const getCSVData = async () => {
        try {
            const result = await crud.get("retrieve/web/csv-data", data);
            // onClose()
            console.log(result.data, "============data")
            setCsvData(result.data)

        } catch (e) {
            console.log(e.message)
        }

    }
    const downloadCSVData = () => {

    }
    const onUserChangeDebounce = debounce(getProfessional, 800);


    return (
        <Fragment>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={isVisible}
                maxWidth={"md"}
                TransitionProps={{}}
            >
                <DialogTitle>
                    <Typography variant="h6" className="font-weight-bolder text-center">Generate CSV</Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <Autocomplete
                                id="asynchronous-demo"
                                className={classes.field}
                                open={open}
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                onChange={(e, value) => Setdata({...data, name: value.name, mobile: value.mobile})}
                                getOptionSelected={(option, value) => option.name === value.name}
                                getOptionLabel={(option) => `${option.name}, ${option.mobile}`}
                                options={options}
                                loading={loading}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Search Professional"
                                        variant="outlined"
                                        onChange={onUserChangeDebounce}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            {/*<Typography variant="body2" className="mb-1">*/}
                            {/*    Name*/}
                            {/*</Typography>*/}
                            {/*<TextField*/}
                            {/*    variant="outlined"*/}
                            {/*    size="small"*/}
                            {/*    className={classes.field}*/}
                            {/*    // value={params?.name || ""}*/}
                            {/*    name="name"*/}
                            {/*    onChange={(e)=>handleChange(e.target.name, e.target.value)}*/}
                            {/*/>*/}
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body2" className="mb-1">
                                From
                            </Typography>
                            <TextField
                                InputLabelProps={{shrink: true}}
                                variant="outlined"
                                name="from"
                                id="date-picker-inline"
                                type="date"
                                format="MM/DD/YYYY"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                className={classes.field}
                            />

                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body2" className="mb-1">
                                To
                            </Typography>
                            <TextField
                                InputLabelProps={{shrink: true}}
                                variant="outlined"
                                name="to"
                                id="date-picker-inline"
                                type="date"
                                format="MM/DD/YYYY"
                                className={classes.field}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                // onChange={(e) => console.log(e.target.name, e.target.value + '00:00')}

                            />

                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {
                        !!csvData?.length &&
                        <DownloadCSV
                            headerString={'#,orderId,category,Sub-cat Name,Customer Name,Customer Mobile No.,ProfessionalId,Professional Name,Paid Amount,Address,Time Slot'}
                            data={csvData.map((e, i) => {
                                if (e.status == 'completed') {
                                    const professional = e.job_order_professionals.find((e) => e.status === "completed")
                                    let obj = {
                                        '#': i++,
                                        'orderId': e.id,
                                        'category': e.category.name,
                                        'Sub-cat Name': e.sub_category.name,
                                        'Customer Name': e.customer.name,
                                        'Customer Mobile No.': e.customer.mobile,
                                        'ProfessionalId': professional?.professional?.id,
                                        'Professional Name': professional?.professional?.name,
                                        'Paid Amount': !e?.additional_services?.length ? (e?.total - e?.discount) : e?.additional_services?.reduce((prev, item) =>
                                            Number(prev) + item?.price, [0]) + e?.total - e?.discount,
                                        'Address': `${e.address.address}-${e.address.city}-${e.address.state}-${e.address.pincode}-${e.address.landmark}`,
                                        'Time Slot': `${moment(e.startTime, "HH:mm").format("h:mm A")}-${moment(e.endTime, "HH:mm").format("h:mm A")}`
                                    }
                                    return obj
                                }
                            }).filter((e) => e)}
                            fileName="order"
                        />
                    }


                    <Button
                        variant="contained"
                        size="small"
                        onClick={onClose}
                        style={{textTransform: "none"}}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={getCSVData}
                        style={{textTransform: "none"}}
                    > Submit
                        {/*{loading ? "Sending..." : "Send"}*/}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default CsvGenerateModal;