import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Chip,
    IconButton,
    makeStyles,
    MenuItem,
    Slide,
    TextField,
    Typography
} from "@material-ui/core";
import { CheckCircleOutline, HighlightOff } from "@material-ui/icons";
import { crud } from "../services/CrudFactory";
import ModalImage from "react-modal-image";


const useStyles = makeStyles(() => ({
    wrongIcon: {
        color: "grey",
        fontSize: "35px",
        float: "left",
    },
    rightIcon: {
        color: "grey",
        fontSize: "35px",
        float: "left",
    },
    CardMedia: {
        borderRadius: "10px",
        width: "100%",
        height: "220px",
    },
    fieldbody: {
        width: "80%",
        height: "35px",
        float: "left",
        borderRadius: "5px",
        padding: "2px",
    }
}));

export default function DialogUsers(props) {
    const classes = useStyles();
    const [professional, setProfessional] = useState({});
    const [wrongOptions, setWrongOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);
    const updateProfessionalKyc = async () => {
        try {
            setLoading(true);
            await crud.update("update/web/kyc-status", {
                id: professional?.id,
                rejectOptions: wrongOptions,
                status
            });
            props.onClose();
            props.getProfessional();
        } finally {
            setLoading(false);
        }
    }


    useEffect(() => {
        console.log(professional, '---------professional-----------');
    }, [professional]);

    return (
        <div>
            <Dialog
                open={props.open}
                maxWidth="lg"
                scroll="body"
                TransitionComponent={Slide}
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                TransitionProps={
                    {
                        onEnter: () => {
                            if (props.professional?.id) {
                                if (!!props.professional?.rejectOptions)
                                    setWrongOptions(JSON.parse(props.professional?.rejectOptions));
                                setProfessional(props.professional);
                                setStatus(props.professional?.kycStatus);
                            }
                        }
                    }
                }
            >
                <DialogTitle>
                    <Grid container spacing={2} justify={"space-between"} alignItems={"center"}>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="h6" className="font-weight-bolder">Kyc Verification</Typography>
                        </Grid>
                        {/*<Grid item xs={12} sm={1}>*/}
                        {/*    <Typography variant="h6" className="mt-3"><span style={{*/}
                        {/*        background: `${user?.data?.kyc_status === "approve" ? "#0bb80b" : "#a79f9f"}`,*/}
                        {/*        padding: "5px 10px",*/}
                        {/*        borderRadius: "10px",*/}
                        {/*        fontSize: "12px",*/}
                        {/*        color: "#FFFFFF"*/}
                        {/*    }}>{user?.data?.kyc_status === "approve" ? "Approved" : "Rejected" || ""}</span></Typography>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} sm={3}>
                            <Typography variant="subtitle2">Status</Typography>
                            <TextField
                                select
                                fullWidth
                                size="small"
                                value={status || ""}
                                onChange={e => setStatus(e.target.value)}
                                variant="outlined"
                                name="status"
                            >
                                <MenuItem value="reject">Reject</MenuItem>
                                <MenuItem value="approve">Approved</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="p">Profile Photo</Typography>
                            <Box borderRadius={14}
                                style={{ border: `5px solid ${!wrongOptions?.includes("profilePhoto") ? "green" : "red"}` }}>
                                <ModalImage
                                    className={classes.CardMedia}
                                    alt="Here is the caption"
                                    small={crud.baseUrl2 + "professional-profiles/" + professional?.profilePicture}
                                    medium={crud.baseUrl2 + "professional-profiles/" + professional?.profilePicture}
                                    large={crud.baseUrl2 + "professional-profiles/" + professional?.profilePicture}
                                />
                            </Box>
                            {
                                !wrongOptions?.includes("profilePhoto") ? <IconButton onClick={() => {
                                    setWrongOptions(prev => {
                                        return [...prev, "profilePhoto"]
                                    });
                                }}>
                                    <HighlightOff
                                        className={classes.wrongIcon}
                                    />
                                </IconButton> : <IconButton onClick={() => {
                                    setWrongOptions(prev => {
                                        const temp = [...prev];
                                        return temp.filter(s => s != "profilePhoto");
                                    });
                                }}>
                                    <CheckCircleOutline
                                        className={classes.rightIcon}
                                    />
                                </IconButton>
                            }


                            <Typography variant="body1" className="mt-2">
                                Name
                            </Typography>
                            <div
                                style={{ border: `1px solid ${!wrongOptions?.includes("name") ? "green" : "red"}` }}
                                className={classes.fieldbody}
                            >
                                {professional?.name}
                            </div>
                            {
                                !wrongOptions?.includes("name") ?
                                    <IconButton onClick={() => {
                                        setWrongOptions(prev => {
                                            return [...prev, "name"]
                                        });
                                    }}>
                                        <HighlightOff
                                            className={classes.wrongIcon}
                                        />
                                    </IconButton> : <IconButton onClick={() => {
                                        setWrongOptions(prev => {
                                            const temp = [...prev];
                                            return temp.filter(s => s != "name");
                                        });
                                    }}>
                                        <CheckCircleOutline
                                            className={classes.rightIcon}
                                        />
                                    </IconButton>
                            }


                            <Typography variant="body1" className="mt-2">
                                Father Name
                            </Typography>
                            <div
                                style={{ border: `1px solid ${!wrongOptions?.includes("fatherName") ? "green" : "red"}` }}
                                className={classes.fieldbody}
                            >
                                {professional?.fatherName || ""}
                            </div>
                            {
                                !wrongOptions?.includes("fatherName") ?
                                    <IconButton onClick={() => {
                                        setWrongOptions(prev => {
                                            return [...prev, "fatherName"]
                                        });
                                    }}>
                                        <HighlightOff
                                            className={classes.wrongIcon}
                                        />
                                    </IconButton> : <IconButton onClick={() => {
                                        setWrongOptions(prev => {
                                            const temp = [...prev];
                                            return temp.filter(s => s != "fatherName");
                                        });
                                    }}>
                                        <CheckCircleOutline
                                            className={classes.rightIcon}
                                        />
                                    </IconButton>
                            }

                        </Grid>


                        <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="p">Aadhar Card Front Side</Typography>
                            <Box borderRadius={14}
                                style={{ border: `5px solid ${!wrongOptions?.includes("frontAdhaarImage") ? "green" : "red"}` }}>
                                <ModalImage
                                    className={classes.CardMedia}
                                    alt="Here is the caption"
                                    small={crud.baseUrl2 + "professional-documents/" + professional.professional_documents?.adhaarFrontImage}
                                    medium={crud.baseUrl2 + "professional-documents/" + professional.professional_documents?.adhaarFrontImage}
                                    large={crud.baseUrl2 + "professional-documents/" + professional.professional_documents?.adhaarFrontImage}
                                />
                            </Box>

                            {
                                !wrongOptions?.includes("frontAdhaarImage") ? <IconButton onClick={() => {
                                    setWrongOptions(prev => {
                                        return [...prev, "frontAdhaarImage"]
                                    });
                                }}>
                                    <HighlightOff
                                        className={classes.wrongIcon}
                                    />
                                </IconButton> : <IconButton onClick={() => {
                                    setWrongOptions(prev => {
                                        const temp = [...prev];
                                        return temp.filter(s => s != "frontAdhaarImage");
                                    });
                                }}>
                                    <CheckCircleOutline
                                        className={classes.rightIcon}
                                    />
                                </IconButton>
                            }

                            <Typography variant="body1" className="mt-2">
                                Aadhar Card Number
                            </Typography>
                            <div
                                style={{ border: `1px solid ${!wrongOptions?.includes("adhaarNumber") ? "green" : "red"}` }}
                                className={classes.fieldbody}
                            >
                                {professional.professional_documents?.adhaarNumber}
                            </div>
                            {
                                !wrongOptions?.includes("adhaarNumber") ? <IconButton onClick={() => {
                                    setWrongOptions(prev => {
                                        return [...prev, "adhaarNumber"]
                                    });
                                }}>
                                    <HighlightOff
                                        className={classes.wrongIcon}
                                    />
                                </IconButton> : <IconButton onClick={() => {
                                    setWrongOptions(prev => {
                                        const temp = [...prev];
                                        return temp.filter(s => s != "adhaarNumber");
                                    });
                                }}>
                                    <CheckCircleOutline
                                        className={classes.rightIcon}
                                    />
                                </IconButton>
                            }

                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="p">Aadhar Card Back Side</Typography>
                            <Box borderRadius={14}
                                style={{ border: `5px solid ${!wrongOptions?.includes("adhaarBackImage") ? "green" : "red"}` }}>
                                <ModalImage
                                    className={classes.CardMedia}
                                    alt="Here is the caption"
                                    small={crud.baseUrl2 + "professional-documents/" +
                                        professional.professional_documents?.adhaarBackImage}
                                    medium={crud.baseUrl2 + "professional-documents/" +
                                        professional.professional_documents?.adhaarBackImage}
                                    large={crud.baseUrl2 + "professional-documents/" +
                                        professional.professional_documents?.adhaarBackImage}
                                />
                            </Box>
                            {
                                !wrongOptions?.includes("adhaarBackImage") ? <IconButton onClick={() => {
                                    setWrongOptions(prev => {
                                        return [...prev, "adhaarBackImage"]
                                    });
                                }}>
                                    <HighlightOff
                                        className={classes.wrongIcon}
                                    />
                                </IconButton> : <IconButton onClick={() => {
                                    setWrongOptions(prev => {
                                        const temp = [...prev];
                                        return temp.filter(s => s != "adhaarBackImage");
                                    });
                                }}>
                                    <CheckCircleOutline
                                        className={classes.rightIcon}
                                    />
                                </IconButton>
                            }
                        </Grid>

                        {/* <Grid item xs={12} sm={6} md={4}> */}
                        {/* <Typography variant="p">
                                Bank Passbook Or cancelled cheque or Declartion Id
                            </Typography>
                            <Box borderRadius={14}
                                 style={{border: `5px solid ${!wrongOptions?.includes("passbookImage") ? "green" : "red"}`}}>
                                <ModalImage
                                    className={classes.CardMedia}
                                    alt="Here is the caption"
                                    small={crud.baseUrl2 + "professional-documents/" + professional.professional_documents?.passbookImage}
                                    medium={crud.baseUrl2 + "professional-documents/" + professional.professional_documents?.passbookImage}
                                    large={crud.baseUrl2 + "professional-documents/" + professional.professional_documents?.passbookImage}
                                 />
                            </Box> */}
                        {/* {
                                !wrongOptions?.includes("passbookImage") ? <IconButton onClick={() => {
                                    setWrongOptions(prev => {
                                        return [...prev, "passbookImage"]
                                    });
                                }}>
                                    <HighlightOff
                                        className={classes.wrongIcon}
                                    />
                                </IconButton> : <IconButton onClick={() => {
                                    setWrongOptions(prev => {
                                        const temp = [...prev];
                                        return temp.filter(s => s != "passbookImage");
                                    });
                                }}>
                                    <CheckCircleOutline
                                        className={classes.rightIcon}
                                    />
                                </IconButton>
                            } */}

                        {/* <Typography variant="body1" className="mt-2">
                                IFSC Code
                            </Typography>
                            <div
                                className={classes.fieldbody}
                                style={{border: `1px solid ${!wrongOptions?.includes("ifsc") ? "green" : "red"}`}}
                            >
                                {professional.professional_documents?.ifsc || ""}
                            </div>
                            {
                                !wrongOptions?.includes("ifsc") ? <IconButton onClick={() => {
                                    setWrongOptions(prev => {
                                        return [...prev, "ifsc"]
                                    });
                                }}>
                                    <HighlightOff
                                        className={classes.wrongIcon}
                                    />
                                </IconButton> : <IconButton onClick={() => {
                                    setWrongOptions(prev => {
                                        const temp = [...prev];
                                        return temp.filter(s => s != "ifsc");
                                    });
                                }}>
                                    <CheckCircleOutline
                                        className={classes.rightIcon}
                                    />
                                </IconButton>
                            }

                            <Typography variant="body1" className="mt-2">
                                Account Number
                            </Typography>
                            <div
                                className={classes.fieldbody}
                                style={{border: `1px solid ${!wrongOptions?.includes("accountNumber") ? "green" : "red"}`}}
                            >
                                {professional.professional_documents?.accountNumber || ""}
                            </div>
                            {
                                !wrongOptions?.includes("accountNumber") ? <IconButton onClick={() => {
                                    setWrongOptions(prev => {
                                        return [...prev, "accountNumber"]
                                    });
                                }}>
                                    <HighlightOff
                                        className={classes.wrongIcon}
                                    />
                                </IconButton> : <IconButton onClick={() => {
                                    setWrongOptions(prev => {
                                        const temp = [...prev];
                                        return temp.filter(s => s != "accountNumber");
                                    });
                                }}>
                                    <CheckCircleOutline
                                        className={classes.rightIcon}
                                    />
                                </IconButton>
                            } */}
                        {/*<Typography variant="body1" className="mt-2">*/}
                        {/*    Declartion Id*/}
                        {/*</Typography>*/}
                        {/*<div*/}
                        {/*    className={classes.fieldbody}*/}
                        {/*    style={{border: `1px solid ${!wrongOptions.includes("declerationId") ? "green" : "red"}`}}*/}
                        {/*>*/}
                        {/*    decleration id*/}
                        {/*</div>*/}
                        {/*        {*/}
                        {/*    !wrongOptions.includes("declerationId") ? <IconButton onClick={()=>{*/}
                        {/*        setWrongOptions(prev=>{*/}
                        {/*            return [...prev,"declerationId"]*/}
                        {/*        });*/}
                        {/*    }}>*/}
                        {/*    <HighlightOff*/}
                        {/*        className={classes.wrongIcon}*/}
                        {/*    />*/}
                        {/*</IconButton> : <IconButton onClick={()=>{*/}
                        {/*        setWrongOptions(prev=>{*/}
                        {/*            const temp = [...prev];*/}
                        {/*           return temp.filter(s=>s != "declerationId");*/}
                        {/*        });*/}
                        {/*    }}>*/}
                        {/*    <CheckCircleOutline*/}
                        {/*        className={classes.rightIcon}*/}
                        {/*    />*/}
                        {/*</IconButton>*/}
                        {/*}*/}


                        {/* </Grid> */}

                        <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="p">Pan Card</Typography>
                            <Box borderRadius={14}
                                style={{ border: `5px solid ${!wrongOptions?.includes("pancardImage") ? "green" : "red"}` }}>
                                <ModalImage
                                    className={classes.CardMedia}
                                    alt="Here is the caption"
                                    small={crud.baseUrl2 + "professional-documents/" + professional.professional_documents?.pancardImage}
                                    medium={crud.baseUrl2 + "professional-documents/" + professional.professional_documents?.pancardImage}
                                    large={crud.baseUrl2 + "professional-documents/" + professional.professional_documents?.pancardImage}

                                />
                            </Box>
                            {
                                !wrongOptions?.includes("pancardImage") ? <IconButton onClick={() => {
                                    setWrongOptions(prev => {
                                        return [...prev, "pancardImage"]
                                    });
                                }}>
                                    <HighlightOff
                                        className={classes.wrongIcon}
                                    />
                                </IconButton> : <IconButton onClick={() => {
                                    setWrongOptions(prev => {
                                        const temp = [...prev];
                                        return temp.filter(s => s != "pancardImage");
                                    });
                                }}>
                                    <CheckCircleOutline
                                        className={classes.rightIcon}
                                    />
                                </IconButton>
                            }

                            <Typography variant="body1" className="mt-2">
                                Pan Card Number
                            </Typography>
                            <div
                                style={{ border: `1px solid ${!wrongOptions?.includes("pancardNumber") ? "green" : "red"}` }}
                                className={classes.fieldbody}
                            >
                                {professional.professional_documents?.pancardNumber || ""}
                            </div>
                            {
                                !wrongOptions?.includes("pancardNumber") ? <IconButton onClick={() => {
                                    setWrongOptions(prev => {
                                        return [...prev, "pancardNumber"]
                                    });
                                }}>
                                    <HighlightOff
                                        className={classes.wrongIcon}
                                    />
                                </IconButton> : <IconButton onClick={() => {
                                    setWrongOptions(prev => {
                                        const temp = [...prev];
                                        return temp.filter(s => s != "pancardNumber");
                                    });
                                }}>
                                    <CheckCircleOutline
                                        className={classes.rightIcon}
                                    />
                                </IconButton>
                            }
                            <div className="d-flex">
                                <div>
                                    <Typography className="mt-2 font-weight-bold">
                                        City
                                    </Typography>
                                    <span>{professional?.city || ""}</span>
                                </div>
                                <div className="ml-3 ">
                                    <Typography className="mt-2 font-weight-bold">
                                        State
                                    </Typography>
                                    <span>{professional?.state || ""}</span>
                                </div>
                                <div className="ml-3 ">
                                    <Typography className="mt-2 font-weight-bold">
                                        Pincode
                                    </Typography>
                                    <span>{professional?.pinCode || ""}</span>
                                </div>
                                <div className="ml-3 ">
                                    <Typography className="mt-2 font-weight-bold">
                                        Full Address
                                    </Typography>
                                    <span>{professional?.fullAddress || ""}</span>
                                </div>
                            </div>


                        </Grid>
                        <Grid>
                            <Typography style={{ color: "#000" }} className="font-weight-bold">Categories :</Typography>
                            {

                                professional?.professional_services?.map(item => <Chip className="ml-2 mt-2"
                                    label={item.categories?.name}
                                    color="primary" />)
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={props.onClose}
                        style={{ margin: "0px 5px" }}
                        color="primary"
                        size="small"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => updateProfessionalKyc()}
                        type="submit"
                    >
                        {loading ? "Submitting..." : "Submit"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
