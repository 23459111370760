import React from 'react';
import {FileCopy} from "@material-ui/icons";
import {Button} from "@material-ui/core";

const DownloadCSV = ({data, headerString, fileName}) => {
    const convertToCSV = (objArray) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';

        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line !== '') line += ',';

                line += array[i][index];
            }
            str += line + '\r\n';
        }
        return headerString + '\r\n' + str;
    };

    const downloadCSV = () => {
        const csvData = new Blob([convertToCSV(data)], {type: 'text/csv'});
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = `${fileName}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Button startIcon={<FileCopy/>} variant="contained" color="primary" onClick={downloadCSV}>DOWNLOAD</Button>
    );
}

export default DownloadCSV;