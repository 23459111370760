import React, {Fragment, useEffect, useState} from "react";
import Appbar from "../components/Appbar";
import Footer from "../components/Footer";
import Drawer from "../components/Drawer";
import {Container, Grid, Typography, Box, makeStyles,withStyles,Paper,Table,TableBody,TableCell,
TableContainer,TableHead,TableRow,TablePagination,} from "@material-ui/core";
import {
    MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker,} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'
import BarChart from "../components/Graphs/BarGraph";
import LineGraph from "../components/Graphs/LineGraph";
import {crud} from "../services/CrudFactory";
import MonthYearDialog from "../dialog/MonthYearDialog";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: "30px",
        marginBottom: "50px",
        padding: "18px"
    },
    comp: {
        width:"100%",
        marginTop: "50px",
        marginBottom: "80px",
        padding:"18px"
    },
    table: {
        minWidth: 700,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,

},
}));
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

export default function Statistics () {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const[statData,setStatData] = useState({})
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [filteredData, setFilteredData] = useState([]);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page when changing rows per page
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };



    const getStatisticsData = async() => {
        const {data} = await crud.get("retrieve/web/statistics");
        // console.log(data,"=============satistics Data");
        setStatData(data);

    }
    // console.log(statData,"===============statData")

   useEffect(()=>{
       getStatisticsData();
   },[])
    //
    // useEffect(() => {
    //
    //     const filtered = statData.filter(item => {
    //         const itemDate = new Date(item.date);
    //         return (
    //             itemDate.getFullYear() === selectedDate.getFullYear() &&
    //             itemDate.getMonth() === selectedDate.getMonth()
    //         );
    //     });
    //     setFilteredData(filtered);
    // }, [selectedDate, statData]);
    //
    // const handleDateChange = (date) => {
    //     setSelectedDate(date);
    //
    //     const month = (date.getMonth() + 1).toString().padStart(2, '0');
    //     const year = date.getFullYear();
    //     const Date = `${year}-${month}`;
    //
    //
    //     axios.post('retrieve/web/statistics', { date: Date })
    //         .then(response => {
    //             console.log(response.statData);
    //
    //         })
    //         .catch(error => {
    //             console.error(error);
    //         });
    // };





    const data = [
        {
            title:"Total Revenue",
            count:statData?.totalRevenue || 0
        },
        {
            title: "Total Orders",
            count:statData?.totalOrder || 0
        },



    ];

    const rows = [];

    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const currentRows = rows.slice(startIndex, endIndex);

    return(

        <Fragment>
            <Appbar/>
            <Drawer/>
            <Container maxWidth="xl" style={{marginBottom: "65px", marginTop: "20px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={6}>
                        <Typography variant="h6" className="font-weight-bolder">
                            Statistics
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}  style={{textAlign:"end"}}>
                            {/* <MonthYearDialog/> */}

                    </Grid>
                </Grid>
                <Paper className={classes.comp}>
                    <Grid container spacing={2} display="flex" justifyContent="center">

                        {
                            data?.map((item,index) => <Grid item xs={12} sm={3} md={3} key={index}>
                                <Box
                                    border={1.6}
                                    borderRadius={10}
                                    p={0.5}
                                    borderColor="primary.main"
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    <Typography style={{fontSize:"15px" }} className="font-weight-bold" >
                                        {item?.title}
                                    </Typography>
                                    <Typography variant="h6" className="font-weight-bold">
                                        {item?.count}
                                    </Typography>
                                </Box>
                            </Grid>)

                        }
                    </Grid>

                </Paper>
                <Paper  className={classes.comp} >
                    <Grid container spacing={3} display="flex" justifyContent="center">
                        <Grid item xs={6}>
                            <BarChart statData={statData}
                                      setStatData={setStatData}


                            />

                        </Grid>
                        <Grid item xs={6}>
                            <LineGraph statData={statData}
                                       setStatData={setStatData}


                            />
                        </Grid>

                    </Grid>
                </Paper>


                <Paper className={classes.comp}>
                    <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">#</StyledTableCell>
                                    <StyledTableCell align="center">Name</StyledTableCell>
                                    <StyledTableCell align="center">Mobile</StyledTableCell>
                                    <StyledTableCell align="center">Total Orders</StyledTableCell>
                                    <StyledTableCell align="center">Total Rejected Order</StyledTableCell>
                                    <StyledTableCell align="center">Total Completed Order</StyledTableCell>
                                </TableRow>
                            </TableHead>{
                            console.log(statData)
                        }
                            <TableBody>
                                {statData?.professionals?.map((row,index) => (
                                    <StyledTableRow key={row.id || index}>
                                        <StyledTableCell component="th" scope="row" align="center">
                                            {row.professional.id}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row"  align="center">
                                            {row.professional.name}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row"  align="center">
                                            {row.professional.mobile}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">{row.completedOrders + row.rejectedOrders}</StyledTableCell>
                                        <StyledTableCell align="center">{row.rejectedOrders}</StyledTableCell>
                                        <StyledTableCell align="center">{row.completedOrders}</StyledTableCell>

                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                    </Grid>

                </Paper>
            </Container>
            <Footer/>
        </Fragment>
    )


}

