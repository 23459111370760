import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/jquery/dist/jquery";
import {CrudProvider} from "@crud/react";
import {
    AlertDialog,
    ConfirmDialog,
    NotifySnackbar,
    ProgressIndicator,
    PromptDialog,
} from "react-material-crud";
import {crud} from "./services/CrudFactory";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#e466a3",
        },
    },
});
ReactDOM.render(<CrudProvider crud={crud}>
        <MuiThemeProvider theme={theme}>
            <ProgressIndicator color="primary"/>
        </MuiThemeProvider>
        <App/>
        <AlertDialog/>
        <ConfirmDialog/>
        <NotifySnackbar autoHideDuration={2000}/>
        <PromptDialog/>
    </CrudProvider>,
    document.getElementById("root")
);
