import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

export default function MultiSelect({setValues, values}) {
    const onChange = (e, value) => {
        setValues(value);
    }
    const cities = [
        'Agra',
        'Mathura',
        'Ahmedabad',
        'Ferozabad',
        'Gandhinagar',
    ];

    return (
        <Autocomplete
            multiple
            id="checkboxes-city"
            options={cities}
            size={"small"}
            disableCloseOnSelect
            value={values}
            getOptionLabel={(option) => option}
            onChange={(e, value) => onChange(e, value)}
            renderOption={(option, {selected}) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                        color="primary"
                    />
                    {option}
                </React.Fragment>
            )}
            renderInput={(params) => (
                <TextField {...params} variant="outlined" name="city" size={"small"} label="City"
                           placeholder="Select city"/>
            )}
        />
    );
}