import React from "react";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {Box, IconButton, Typography} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import {Link} from "react-router-dom";
import logo from "../../images/logo.png";
import {Dashboard} from "@material-ui/icons";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import PeopleIcon from "@material-ui/icons/People";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import CategoryIcon from "@material-ui/icons/Category";
import StorefrontIcon from "@material-ui/icons/Storefront";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import InfoIcon from "@material-ui/icons/Info";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import {VIEW_NOTIFICATIONS, VIEW_USERS, VIEW_KYC, PERMANENET_HIDE, VIEW_CUSTOMERS} from '../../constant';
import {$user} from "../../services/UserFactory";
import {FormControl,InputLabel,Select,MenuItem} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
    menu: {
        [theme.breakpoints.up("lg")]: {
            display: "none !important"
        },
    }
}));

export default function Drawer(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setState({...state, [anchor]: open});
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom",
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Box>
                <List>
                    <Box style={{textAlign: "center"}}>
                        <Link to="/dashboard">
                            <img
                                src={logo}
                                width="50"
                                height="50"
                                //   className="side-logo"
                                alt="logo"
                            />
                        </Link>
                    </Box>
                    <Divider/>
                    <ListItem button to="/" component={Link}>
                        <ListItemIcon className="m-0">
                            <Dashboard color="primary"/>
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" color="primary"/>
                    </ListItem>

                    <ListItem button to="/jobs" component={Link}>
                        <ListItemIcon>
                            <WorkOutlineIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText primary="Jobs" color="primary"/>
                    </ListItem>

                    {$user.hasPermission(VIEW_KYC) &&
                    <ListItem button to="/professional-kyc" component={Link}>
                        <ListItemIcon>
                            <i className="far fa-id-card" id="drawer_icon_color"/>
                        </ListItemIcon>
                        <ListItemText primary="Professional Kyc" color="primary"/>
                    </ListItem>}

                    {$user.hasPermission(VIEW_NOTIFICATIONS) &&
                    <ListItem button to="/notification" component={Link}>
                        <ListItemIcon>
                            <AddAlertIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText primary="Notification" color="primary"/>
                    </ListItem>}

                    <ListItem button to="/support-ticket" component={Link}>
                        <ListItemIcon>
                            <HeadsetMicIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText primary="Support Ticket" color="primary"/>
                    </ListItem>

                    <ListItem button to="/customer-user" component={Link}>
                        <ListItemIcon>
                            <PeopleIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText primary="Customer User" color="primary"/>
                    </ListItem>

                    <ListItem button to="/professional-user" component={Link}>
                        <ListItemIcon>
                            <i className="fas fa-user-tie" id="drawer_icon_color"/>
                        </ListItemIcon>
                        <ListItemText primary="Professional User" color="primary"/>
                    </ListItem>

                    {$user.hasPermission(VIEW_USERS) &&
                    <ListItem button to="/panel-users" component={Link}>
                        <ListItemIcon>
                            <GroupAddIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText primary="Panel users" color="primary"/>
                    </ListItem>}

                    {$user.hasPermission(PERMANENET_HIDE) &&
                    <ListItem button to="/sliders" component={Link}>
                        <ListItemIcon>
                            <SlideshowIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText primary="Sliders" color="primary"/>
                    </ListItem>}

                    {$user.hasPermission(PERMANENET_HIDE) &&
                    <ListItem button to="/categories" component={Link}>
                        <ListItemIcon>
                            <CategoryIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText primary="Categories" color="primary"/>
                    </ListItem>}

                    {$user.hasPermission(PERMANENET_HIDE) &&
                    <ListItem button to="/services" component={Link}>
                        <ListItemIcon>
                            <StorefrontIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText primary="Service" color="primary"/>
                    </ListItem>}

                    {$user.hasPermission(PERMANENET_HIDE) &&
                    <ListItem button to="/faqs" component={Link}>
                        <ListItemIcon>
                            <LiveHelpIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText primary="Faq's" color="primary"/>
                    </ListItem>}

                    {$user.hasPermission(PERMANENET_HIDE) &&
                    <ListItem button to="/about-us" component={Link}>
                        <ListItemIcon>
                            <InfoIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText primary="About Us" color="primary"/>
                    </ListItem>}

                    <ListItem button to="/logout" component={Link}>
                        <ListItemIcon>
                            <LockOpenIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText primary="Log&nbsp;Out"/>
                    </ListItem>


                </List>
            </Box>
        </div>
    );

    return (
        <div>
            <React.Fragment key={"left"}>
                <IconButton
                    onClick={toggleDrawer("left", true)}
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                >
                    <MenuIcon className={classes.menu}></MenuIcon>
                </IconButton>
                <SwipeableDrawer
                    anchor="left"
                    open={state["left"]}
                    onMouseOver={toggleDrawer("left", true)}
                    onClose={toggleDrawer("left", false)}
                    onOpen={toggleDrawer("left", true)}
                >
                    {list("left")}
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}
