import {io} from "socket.io-client";

// const url = "https://services4home-node.technosters.net";
const url = "https://services4home.in";

export const socket = io("https://services4home.in", {
    autoConnect: true,
    auth: {userId : "", type: "Web"},
    transports: ["websocket"],
    path: "/socket.io/",
})

// socket.emit("hello", {h:"hello"})

socket.onAny((event, ...args) => {
    console.log(event, args, "socket");
})