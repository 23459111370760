import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField} from "@material-ui/core";

const RevenueDialog = (props) => {
    console.log(props, "=========================props")
    const handleChange = (event) => {
        const value = event.target.value;
        if (value >= 0 && value <= 40) {
            props.handleRevenuePercent(event);
        }
    };

    return (
        <>
            <Dialog open={props.open.isOpen} onClose={props.handleClose}
                    aria-labelledby="form-dialog-title" maxWidth='xs' fullWidth={true}>
                <DialogTitle id="form-dialog-title">Edit Revenue Percentage</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id={props?.open?.data?.id}
                        name="revenuePercentage"
                        // value={props.revPercentValue}
                        defaultValue={props?.open?.data}
                        label="revenue percentage"
                        type="number"
                        inputProps={{ min: 0, max: 40 }}
                        fullWidth
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={props.addRevenuePercent} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default RevenueDialog;