import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Divider,
    TextField,
    TextareaAutosize,
} from "@material-ui/core";
import {crud} from "../services/CrudFactory";

export default function FaqsDialog(props) {

    const [params, setParams] = useState({type:"PROFESSIONAL"});

    const [loading, setLoading] = useState(false);

    const valueChange = (e,newValue) => {
        const {value, name} = e.target;
        // console.log(e.target.name,e.target.value)
        setParams(prev => ({
            ...prev,
            ...newValue

        }));
    };

    const save = async () => {
        try {
            console.log(params,"=========params");
            setLoading(true);
            await crud.post(params?.id ? "update/web/faq" : "create/web/faq", params);

            cancel();
            props.onSave();
        } finally {
            setLoading(false);
        }
    };
    const cancel = () => {
        props.close();
        // setParams({});
    };
    console.log(params,"=======params")


    useEffect(()=>{
        setParams(((props?.faq)?.hasOwnProperty('id'))? props.faq : {type:'PROFESSIONAL'});
    },[props.faq])
    return (
        <div>
            <Dialog
                open={props.open}
                maxWidth={"sm"}
            >
                <DialogTitle>
                    <Typography variant="h5">{params.id ? "Update Faq" : "Create Faq"}</Typography>
                </DialogTitle>
                <Divider/>
                <DialogContent className="mt-2">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                select
                                label="Faq For"
                                fullWidth
                                variant="outlined"
                                size="small"
                                SelectProps={{
                                    native: true,
                                }}
                                name="type"
                                value={params?.type || "PROFESSIONAL"}
                                onChange={e => valueChange(e,{type:e.target.value})}
                            >
                                <option value="PROFESSIONAL">Professional</option>
                                <option value="CUSTOMER">Customer</option>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">Question</Typography>
                            <TextField
                                variant="outlined"
                                size="small"
                                margin="normal"
                                fullWidth
                                placeholder="Question Type Here"
                                name="ques"
                                value={params?.ques || ""}
                                onChange={e => valueChange(e,{ques:e.target.value})}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="body2" className="mb-3">
                                Answer
                            </Typography>
                            <TextareaAutosize
                                maxRows={4}
                                aria-label="maximum height"
                                placeholder="Answer Type Here"
                                name="answer"
                                value={params?.answer || ""}
                                onChange={e => valueChange(e,{answer:e.target.value})}
                                style={{
                                    width: "100%",
                                    height: "100px",
                                    borderRadius: "5px",
                                    outline: "none",
                                    padding: "5px",
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider/>

                <DialogActions>
                    <Button
                        variant="outlined"
                        // onClick={CreateSubmit}
                        onClick={() => cancel()}
                        color="primary"
                        size="small"
                        style={{textTransform: "none"}}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        variant="contained"
                        onClick={() => save()}
                        color="primary"
                        size="small"
                        type="submit"
                        style={{textTransform: "none"}}
                    >
                        {
                            !params?.id ? (loading ? "Creating..." : "Create") : (loading ? "Updating..." : "Update")
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
